import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  makeStyles,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
  Typography,
  FormLabel,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { HttpsOutlined } from '@material-ui/icons';
import { useDataProvider, useQuery, useMutation, useVersion } from 'react-admin';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { get, isEqual } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';

import { BillDialog } from 'components/common';
import { Group, GroupContent, GroupItem, GroupTitle } from 'components/common/Group';
import { LabeledPriceField } from 'components/fields';
import { CountriesSelect, Input, StateSelect, Stripe } from 'components/form';
import { composeValidators, creditCodeValidator, required } from 'components/form/validationRules';
import { ToLocation, PoweredByStripe } from 'icons';
import formatPrice from 'services/helpers/formatPrice';
import getAll from 'services/helpers/getAll';
import AutoRefillSwitch from './components/AutoRefillSwitch';
import AutoRefillSetting from './components/AutoRefillSetting';
import SavedCards from './components/SavedCards';
import NewCardButton from './components/NewCardButton';
import SinglePayAmount from './components/SinglePayAmount';
import { httpClient } from 'services/data';

import useCustomNotify from 'hooks/useCustomNotify';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddressDistributor from '../../form/AddressDistributor';
import { removePaymentAmount } from 'store/actions/refillActions';

import { pluralize } from 'services/helpers/formatters';
import useProfile from 'hooks/useProfile';

const useStyles = makeStyles((theme) => ({
  calcBlock: {
    padding: theme.spacing(0, 3, 2),
  },

  calcRow: {
    padding: theme.spacing(1, 0),
  },

  calcTotal: {
    fontWeight: 'bold',
    fontSize: 20,
  },

  secureGroup: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(1, 3, 3),
    borderRadius: theme.spacing(2),
  },

  secureGroupTitle: {
    backgroundColor: 'transparent',
    padding: theme.spacing(1.5, 0, 0),
    margin: 0,
  },

  stripeLogo: {
    position: 'absolute',
    top: theme.spacing(2.5),
    right: theme.spacing(3),
  },

  tabs: {
    padding: theme.spacing(0, 2),
    marginBottom: '20px',
  },
  dialogBody: { maxWidth: 480, margin: '0 auto' },
  autoRefillMessage: { margin: '15px 0 5px 0' },
  error: {
    fontSize: 14,
    color: theme.palette.error.main,
    padding: theme.spacing(0, 0, 0.5, 3),
  },
  button: {
    color: theme.palette.secondary.contrastText,
    border: `1px solid #E0E0E0`,
  },
  creditInfo: {
    color: '#757575',
    fontSize: 14,
  },
  autoRefillMessageContainer: {
    padding: theme.spacing(0, 3),
  },
  shipmentCredit: {
    padding: theme.spacing(0, 3),
  },
  groupContent: {
    padding: theme.spacing(0, 2),
  },
  addressGroupTitle: {
    margin: 0,
    padding: theme.spacing(1.5, 2),
  },
  processingPaymentLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  processingPaymentText: {
    fontWeight: 700,
    color: theme.palette.common.white,
  },
}));

const AUTO_REFILL_TAB = 'Auto-Refill';
const SINGLE_PAYMENT_TAB = 'Single Payment';
const SHIPMENT_CREDIT_TAB = 'Shipment credit';
const MANAGE_PAGE = 'Manage';
const ADD_BALANCE_PAGE = 'ADD BALANCE';
const ADD_NEW_CARD_PAGE = 'ADD NEW CARD';
const NEW_CARD = 'NEW_CARD';
const STATUS_CHECK_INTERVAL = 3000;
const PAYMENT_SUCCESSFUL = 'rollo_succeeded';
const TIMEOUT = 15000;

let timeoutId;

interface CardInfo {
  cardId: string;
  brand: string;
  lastDigits: string;
  failedLastTime: boolean;
}

const formatAmount = (amount) =>
  formatPrice(amount, { minimumFractionDigits: 0, maximumFractionDigits: 0 });

const requireCard = (value) => {
  if (!value) {
    return 'Required';
  }

  if (value.error) {
    return value.error.message;
  }

  if (!value.complete) {
    return 'Required';
  }
};

const stripePromise = async () => {
  const apiUrl = `${process.env.REACT_APP_DATA_PROVIDER_URL}/payments/settings`;
  const options = {
    method: 'GET',
  };
  const { json } = await httpClient(apiUrl, options);
  return loadStripe(json.stripePublishableKey as string, { apiVersion: '2022-08-01' });
};

const formatCreditCode = (value: string) => value.replace(/[^A-Za-z0-9]*/g, '');

const PaymentProcessingLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.processingPaymentLoader}>
      <CircularProgress size={24} />
      <Typography className={classes.processingPaymentText}>Processing payment</Typography>
    </div>
  );
};

const RefillDialogImpl = (props: any) => {
  const notify = useCustomNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const stripe = useStripe();
  const elements = useElements();
  const version = useVersion();
  const location = useLocation();
  const dispatch = useDispatch();

  const { data: paymentOptions } = useQuery({
    type: 'GET_LIST',
    resource: 'paymentOptions',
    payload: {},
  });
  const { profile } = useProfile();

  const [completed, setCompleted] = useState(false);
  const callback = useSelector((state) => state.refill.callback, shallowEqual);
  const openSingleTabByDefault = useSelector(
    (state) => state.refill.openSingleTabByDefault,
    shallowEqual
  );
  const isWelcomePage = useSelector((state) => state.common.isWelcomePage, shallowEqual);
  const singlePaymentAmount = useSelector(
    (state) => state.refill.singlePaymentAmount,
    shallowEqual
  );

  useEffect(() => {
    if (singlePaymentAmount) {
      setErrorAmount(singlePaymentAmount);

      dispatch(removePaymentAmount());
    }
  }, [dispatch, singlePaymentAmount]);

  const [getWarehouse, { data: warehouses }] = useMutation({
    type: 'GET_LIST',
    resource: 'warehousePresets',
    payload: { pagination: { perPage: 1 } },
  });

  useEffect(() => {
    if (callback) {
      getWarehouse();
    }
  }, [callback, getWarehouse]);

  const [pageType, setPageType] = useState(AUTO_REFILL_TAB);
  const [pageTitle, setPageTitle] = useState(MANAGE_PAGE);
  const [removingCard, setRemovingCard] = useState('');

  const [pickedCard, setPickedCard] = useState('');
  const [autoRefillAmount, setAutoRefillAmount] = useState(100);

  const [defaultCard, setDefaultCard] = useState(NEW_CARD);
  const [autoRefillCard, setAutoRefillCard] = useState(NEW_CARD);
  const [cards, setCards] = useState<CardInfo[] | null>(null);
  const [enabledAutoRefill, setEnabledAutoRefill] = useState(false);
  const [saveCard, setSaveCard] = useState(true);
  const [failedCard, setFailedCard] = useState<string>('');
  const [showParser, setShowParser] = useState(false);
  const [currentAutoRefillStatus, setCurrentAutoRefillStatus] = useState(false);
  const [paymentLimits, setPaymentLimits] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorAmount, setErrorAmount] = useState<number>(0);

  useEffect(() => {
    dataProvider.get('paymentLimits').then(({ data }) => setPaymentLimits(data));
  }, [dataProvider]);

  const handleData = async (isInitial?) => {
    const { data: billingData } = await dataProvider.get('billingSettings');

    setCards(billingData.cards);

    if (billingData.autoRefill.enabled) {
      setAutoRefillAmount(formatAutoRefillAmount(billingData.autoRefill.amount, false));
    }

    setAutoRefillCard(billingData.autoRefill.defaultCardId);
    setDefaultCard(billingData.defaultCardId);

    setCurrentAutoRefillStatus(billingData.autoRefill.enabled);

    if (isInitial || billingData.cards.length <= 0) {
      setEnabledAutoRefill(billingData.autoRefill.enabled);
    }

    if (billingData.autoRefill.enabled) {
      const selectedCardId = billingData.autoRefill.defaultCardId;

      const selectedCard = billingData.cards?.find((card) => card.cardId === selectedCardId);

      setFailedCard(selectedCard?.failedLastTime ? selectedCard?.lastDigits : '');
    } else {
      setFailedCard('');
    }

    return billingData;
  };

  useEffect(() => {
    handleData(true).catch((error) => {
      console.log('Error:', error);
    });
  }, [version]); //eslint-disable-line

  useEffect(() => {
    if (openSingleTabByDefault) setPageType(SINGLE_PAYMENT_TAB);
  }, [openSingleTabByDefault]);

  const clearData = (enabledAutoRefill) => {
    setPageType(AUTO_REFILL_TAB);
    setPageTitle(MANAGE_PAGE);
    setRemovingCard('');
    setSaveCard(true);
    setPickedCard('');
    setEnabledAutoRefill(
      typeof enabledAutoRefill === 'boolean' ? enabledAutoRefill : currentAutoRefillStatus
    );
    handleData().catch((error) => {
      console.log('Error:', error);
    });
  };

  const resetTimeout = () => {
    clearTimeout(timeoutId);
    timeoutId = null;
  };

  const getPaymentStatus = async (id: string, amount: number) => {
    try {
      const {
        data: { status },
      } = await dataProvider.getNest('paymentSession', { id });

      if (status === PAYMENT_SUCCESSFUL) {
        setLoading(false);
        notify(`${formatPrice(amount)} is added to your account`, 'billing');
        handleClose(currentAutoRefillStatus, true);
        resetTimeout();
      }

      await new Promise((resolve) => setTimeout(resolve, STATUS_CHECK_INTERVAL));

      return timeoutId ? await getPaymentStatus(id, amount) : null;
    } catch (error) {
      throw error;
    }
  };

  const getPaymentReview = async (id: string) => {
    try {
      const {
        data: { review },
      } = await dataProvider.getNest('paymentReview', { id });

      if (review) {
        notify(
          'This payment requires a manual review. Please allow up to 1 business day. Check status under Deposit tab.',
          'error'
        );
        handleClose(currentAutoRefillStatus, true);
        setLoading(false);
        resetTimeout();
      }

      return review;
    } catch (error) {
      throw error;
    }
  };

  const setPaymentTimeout = () =>
    setTimeout(() => {
      resetTimeout();
      setLoading(false);
      handleClose(currentAutoRefillStatus, true);
      notify(
        'Payment processing takes more time than usual. Your balance will be updated once payment is received. Please check your balance in a few minutes.',
        'error'
      );
    }, TIMEOUT);

  const makeSinglePayment = async (values) => {
    try {
      if (!stripe || !elements) {
        throw Error("Stripe hasn't been loaded");
      }

      setLoading(true);

      const customAmount = parseFloat(values.customAmount);

      timeoutId = setPaymentTimeout();

      const {
        data: { clientKey, id },
      } = await dataProvider.post('paymentIntents', {
        data: {
          option: values.paymentOption.option,
          billingAddress: {
            ...getAll(values, {
              name: 'name',
              addressLine1: 'address.line1',
              addressLine2: 'address.line2',
              city: 'address.city',
              stateProvinceCode: 'address.state',
              countryCode: 'address.country',
              postalCode: 'address.postal_code',
            }),
            email: profile && profile.email,
            phone: profile && profile.phone_number,
          },
          saveCard: saveCard,
          customAmount,
        },
      });

      const result = await stripe.confirmCardPayment(clientKey, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: values.name as string,
            email: profile && profile.email,
            phone: profile && profile.phone_number,
            address: values.address,
          },
        },
      });

      if (result.error) throw result.error;

      const review = await getPaymentReview(id);

      if (review) return;

      await getPaymentStatus(id, customAmount);

      return result;
    } catch (error) {
      resetTimeout();
      setLoading(false);
      return { [FORM_ERROR]: error.message };
    }
  };

  const handleRemoveCard = async (cardId, event) => {
    event.stopPropagation();
    setRemovingCard(cardId);
    await dataProvider.delete('removeCard', { id: cardId });

    if (pickedCard === cardId) {
      setPickedCard('');
    }

    try {
      await handleData();
      notify('A card removed!', 'success');
    } catch {}
  };

  const makePaymentBySavedCard = async (values, savedCardId) => {
    try {
      if (!stripe || !elements) {
        throw Error("Stripe hasn't been loaded");
      }

      setLoading(true);

      const customAmount = parseFloat(values.customAmount);

      timeoutId = setPaymentTimeout();

      const {
        data: { clientKey, id },
      } = await dataProvider.post('paymentIntents', {
        data: {
          option: values.paymentOption.option,
          paymentMethodId: savedCardId,
          customAmount,
        },
      });

      const result = await stripe.confirmCardPayment(clientKey, {
        payment_method: savedCardId,
      });

      if (result.error) throw result.error;

      const review = await getPaymentReview(id);

      if (review) return;

      await getPaymentStatus(id, customAmount);

      return result;
    } catch (error) {
      resetTimeout();
      setLoading(false);
      return { [FORM_ERROR]: error.message };
    }
  };

  const handleChangeTab = (form) => (event, value) => {
    form.reset(initialValues);
    setPageType(value);
  };

  const handleChooseCard = (cardId) => {
    setPickedCard(cardId);
  };

  const handleClose = (enabledAutoRefill: boolean, lastCompleted?: boolean) => {
    setErrorAmount(0);

    if (isWelcomePage) {
      setCompleted((prevState) => {
        callback(null, prevState);
        clearData(enabledAutoRefill);
        return false;
      });
      return;
    }

    if (pageType === ADD_NEW_CARD_PAGE && !completed) {
      setPageType(AUTO_REFILL_TAB);
      setPageTitle(MANAGE_PAGE);
      return;
    }

    if (pageType === ADD_BALANCE_PAGE && !completed && !lastCompleted) {
      setPageType(SINGLE_PAYMENT_TAB);
      setPageTitle(MANAGE_PAGE);
      return;
    }

    setCompleted((prevState) => {
      callback(null, prevState);
      clearData(enabledAutoRefill);
      return false;
    });
  };

  const handleAddNewCard = () => {
    setPageTitle(ADD_NEW_CARD_PAGE);
    setPageType(ADD_NEW_CARD_PAGE);
  };

  const changeAutoRefillAmount = (e) => {
    setAutoRefillAmount(e.target.value);
  };

  const formatAutoRefillAmount = (amount, type) => {
    if (!type) {
      return amount.substr(12);
    } else {
      return 'AUTO_REFILL_' + amount;
    }
  };

  const addNewCard = async (values, form) => {
    if (!stripe || !elements) {
      throw Error("Stripe hasn't been loaded");
    }

    const {
      data: { clientKey },
    } = await dataProvider.get('setupIntent');
    const { setupIntent: data, error: cardSetupError } = await stripe.confirmCardSetup(clientKey, {
      payment_method: {
        card: elements.getElement(CardElement)!,
        billing_details: {
          name: values.name as string,
          email: profile && profile.email,
          phone: profile && profile.phone_number,
          address: values.address,
        },
      },
    });

    if (cardSetupError) throw cardSetupError;

    const intentId = data!.id;
    await dataProvider.post('setupIntent', {
      data: {
        setupIntentId: intentId,
        source: 'AUTO_REFILL',
        billingAddress: {
          ...getAll(values, {
            name: 'name',
            addressLine1: 'address.line1',
            addressLine2: 'address.line2',
            city: 'address.city',
            stateProvinceCode: 'address.state',
            countryCode: 'address.country',
            postalCode: 'address.postal_code',
          }),
          email: profile && profile.email,
          phone: profile && profile.phone_number,
        },
      },
    });

    notify('A card added!', 'success');
    try {
      await handleData();
      setPageType(AUTO_REFILL_TAB);
      form.initialize(initialValues);
      setPageTitle(MANAGE_PAGE);
    } catch {}
  };

  const updateAutoRefill = async (enabled, amount, card, isNotify = true) => {
    try {
      if (card?.length > 0) {
        const result = await dataProvider.put('billingSettings', {
          data: {
            autoRefill: {
              enabled: enabled,
              amount: 'AUTO_REFILL_' + amount,
              defaultCardId: card,
            },
          },
        });

        if (result?.data?.autoRefill) {
          setCurrentAutoRefillStatus(!!result.data.autoRefill.enabled);
        }

        setDefaultCard(card);
        if (isNotify) {
          notify('Auto-Refill setting updated!', 'success');
          handleClose(!!result.data.autoRefill.enabled);
        }
      } else {
        throw new Error('Please add a payment card!');
      }
    } catch (error) {
      if (error.message.includes('review')) {
        notify(
          'This payment requires a manual review. Please allow up to 1 business day. Check status under Deposit tab.',
          'error'
        );
        handleClose(false);
        return;
      }

      return { [FORM_ERROR]: error.message };
    }
  };

  const submitCreditCode = async (values) => {
    const { data } = await dataProvider.post('creditCodes', {
      data: {
        code: values.creditCode,
      },
    });
    const { data: codeInfo } = await dataProvider.getOne('creditCodes', { id: values.creditCode });
    notify(
      `${
        codeInfo?.type === 'fund'
          ? `${formatPrice(codeInfo?.price, {
              minimumFractionDigits: 0,
            })} has`
          : pluralize(codeInfo?.price, 'free label has', 'free labels have')
      } been added to your balance`,
      'success'
    );
    handleClose(currentAutoRefillStatus);
    return data;
  };

  const handleSubmitForm = (values, form) => {
    setErrorAmount(0);
    if (pageType === SINGLE_PAYMENT_TAB) {
      if (pickedCard === NEW_CARD) {
        setPageType(ADD_BALANCE_PAGE);
        setPageTitle(ADD_BALANCE_PAGE);
        return;
      } else if (pickedCard === '') {
        return makePaymentBySavedCard(values, defaultCard);
      } else {
        return makePaymentBySavedCard(values, pickedCard);
      }
    }
    if (pageType === ADD_BALANCE_PAGE) {
      return makeSinglePayment(values);
    }
    if (pageType === ADD_NEW_CARD_PAGE) {
      return addNewCard(values, form).catch((err) => ({
        [FORM_ERROR]: err.message,
      }));
    }
    if (pageType === AUTO_REFILL_TAB) {
      const autoCard = !pickedCard ? autoRefillCard : pickedCard;
      return updateAutoRefill(enabledAutoRefill, autoRefillAmount, autoCard);
    }
    if (pageType === SHIPMENT_CREDIT_TAB) {
      return submitCreditCode(values).catch((err) => ({
        [FORM_ERROR]: err.message,
      }));
    }
  };

  const initialValues = useMemo(
    () => ({
      paymentOption: null,
      name: null,
      address: {
        country: 'US',
        line1: get(warehouses, '[0].addressLine1'),
        line2: get(warehouses, '[0].addressLine2'),
        city: get(warehouses, '[0].city'),
        state: get(warehouses, '[0].stateOrProvince.code'),
        postal_code: get(warehouses, '[0].postalCode'),
      },
      customAmount: null,
    }),
    [warehouses]
  );

  if (!callback) {
    return null;
  }

  return (
    <Form
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      onSubmit={handleSubmitForm}
      render={({
        handleSubmit,
        submitting,
        values,
        submitError,
        form,
        initialValues,
        ...renderProps
      }) => {
        const handleChangeAmount = (value) => {
          const convertedValue = parseFloat(value);
          const matchOption = paymentOptions?.find(
            (option) => option.baseAmount === convertedValue
          );

          if (matchOption) {
            form.batch(() => {
              form.change('paymentOption', matchOption);
              form.change('customAmount', value);
            });
            return;
          }

          const currentFee =
            paymentLimits?.fees?.find(
              (fee) => fee.max > convertedValue && fee.min <= convertedValue
            )?.fee ?? 0;
          form.batch(() => {
            form.change('paymentOption', {
              option: null,
              baseAmount: convertedValue,
              stripeFee: currentFee,
              totalAmount: convertedValue + currentFee,
            });
            form.change('customAmount', value);
          });
        };

        if (errorAmount && !values.customAmount) {
          handleChangeAmount(errorAmount);
        }

        return (
          <form onSubmit={handleSubmit}>
            <BillDialog
              open={true}
              title={pageTitle}
              submitting={submitting || loading}
              confirmText="Submit"
              onCancel={handleClose}
              onConfirm={handleSubmit}
              confirmDisabled={pageType === AUTO_REFILL_TAB && !pickedCard && !autoRefillCard}
              progress={loading ? <PaymentProcessingLoader /> : undefined}>
              <div className={classes.dialogBody}>
                {(pageType === AUTO_REFILL_TAB ||
                  pageType === SINGLE_PAYMENT_TAB ||
                  pageType === SHIPMENT_CREDIT_TAB) && (
                  <Tabs
                    className={classes.tabs}
                    value={pageType}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab(form)}>
                    <Tab value={AUTO_REFILL_TAB} label="Auto-Refill" />
                    <Tab value={SINGLE_PAYMENT_TAB} label="Single Payment" />
                    <Tab value={SHIPMENT_CREDIT_TAB} label={SHIPMENT_CREDIT_TAB} />
                  </Tabs>
                )}
                {pageType === AUTO_REFILL_TAB && (
                  <>
                    {enabledAutoRefill && failedCard && (
                      <div className={classes.error}>
                        We were unable to charge the card ending in {failedCard}. Please check with
                        card issuer or use another payment method.
                      </div>
                    )}
                    <AutoRefillSwitch
                      checked={enabledAutoRefill}
                      onChange={(e) => setEnabledAutoRefill(e.target.checked)}
                    />
                    {enabledAutoRefill ? (
                      <AutoRefillSetting
                        autoRefillAmount={autoRefillAmount}
                        changeAutoRefillAmount={changeAutoRefillAmount}
                      />
                    ) : (
                      <div className={classes.autoRefillMessageContainer}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.autoRefillMessage}>
                          Auto-refill automatically funds account once your balance drops below your
                          chosen threshold.
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Please toggle the switch above to enable Auto-refill or press Single
                          Payment tab above to fund your account
                        </Typography>
                      </div>
                    )}
                  </>
                )}
                {pageType === SINGLE_PAYMENT_TAB && (
                  <>
                    {!!errorAmount && location.pathname.includes('/orders/groups') && (
                      <div className={classes.error}>
                        Please add at least {formatPrice(errorAmount)} funds or enable auto-refill
                        to complete this transaction.
                      </div>
                    )}
                    <SinglePayAmount
                      options={paymentOptions.map((option) => ({
                        label: formatAmount(option.baseAmount),
                        value: option,
                      }))}
                      form={form}
                      paymentLimits={paymentLimits}
                      values={values}
                      handleChangeAmount={handleChangeAmount}
                    />
                    {values.paymentOption && (
                      <GroupContent className={classes.calcBlock}>
                        <GroupItem wide>
                          <LabeledPriceField
                            classes={{ root: classes.calcRow }}
                            label="Payment processing fee"
                            record={values}
                            source="paymentOption.stripeFee"
                            info="The fee is paid to the payment processing company and is not
                          collected by Rollo."
                          />
                        </GroupItem>
                        <GroupItem wide>
                          <LabeledPriceField
                            classes={{
                              root: classes.calcRow,
                              price: classes.calcTotal,
                            }}
                            label="Total pay"
                            record={values}
                            source="paymentOption.totalAmount"
                          />
                        </GroupItem>
                      </GroupContent>
                    )}
                  </>
                )}
                {pageType === AUTO_REFILL_TAB && enabledAutoRefill && (
                  <>
                    {cards && (
                      <SavedCards
                        pageType={pageType}
                        cards={cards}
                        defaultCard={autoRefillCard}
                        handleRemoveCard={handleRemoveCard}
                        removing={removingCard}
                        onChange={handleChooseCard}
                        submitError={submitError}
                      />
                    )}
                    <NewCardButton onClick={handleAddNewCard} />
                  </>
                )}
                {pageType === SINGLE_PAYMENT_TAB && (
                  <>
                    {cards && (
                      <SavedCards
                        pageType={pageType}
                        cards={cards}
                        defaultCard={defaultCard && cards?.length ? defaultCard : NEW_CARD}
                        handleRemoveCard={handleRemoveCard}
                        removing={removingCard}
                        onChange={handleChooseCard}
                        submitError={submitError}
                      />
                    )}
                  </>
                )}
                {(pageType === ADD_BALANCE_PAGE || pageType === ADD_NEW_CARD_PAGE) && (
                  <>
                    <Group>
                      <GroupTitle className={classes.addressGroupTitle} icon={<ToLocation />}>
                        <Grid container justify={'space-between'} alignItems={'flex-start'}>
                          <Grid item>Billing address</Grid>
                          <Grid item>
                            <Button
                              size="small"
                              className={classes.button}
                              endIcon={showParser ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                              onClick={() => setShowParser(!showParser)}>
                              Paste US Address
                            </Button>
                          </Grid>
                        </Grid>
                      </GroupTitle>
                      <GroupContent className={classes.groupContent}>
                        {showParser && (
                          <GroupItem wide>
                            <Input
                              name="string"
                              label="Paste or type US address here..."
                              multiline={true}
                            />
                            <AddressDistributor
                              field="string"
                              targets={[
                                'name',
                                'address.line1',
                                'address.line2',
                                'address.city',
                                'address.state',
                                'address.postal_code',
                              ]}
                            />
                          </GroupItem>
                        )}
                        <GroupItem>
                          <Input name="address.line1" label="Address Line 1" validate={required} />
                        </GroupItem>
                        <GroupItem>
                          <Input name="address.line2" label="Address Line 2" />
                        </GroupItem>
                        <GroupItem>
                          <Input name="address.city" label="City / Town" validate={required} />
                        </GroupItem>
                        <GroupItem>
                          <StateSelect
                            label="State"
                            name="address.state"
                            countryCode={get(values, 'address.country')}
                            validate={required}
                          />
                        </GroupItem>
                        <GroupItem>
                          <Input label="ZIP" name="address.postal_code" validate={required} />
                        </GroupItem>
                        <GroupItem>
                          <CountriesSelect
                            label="Country"
                            name="address.country"
                            disabled={true}
                            validate={required}
                          />
                        </GroupItem>
                      </GroupContent>
                    </Group>
                    <Group className={classes.secureGroup}>
                      <GroupTitle className={classes.secureGroupTitle} icon={<HttpsOutlined />}>
                        <Grid container alignItems="flex-start" justify="space-between">
                          <Grid item>Secure Payment</Grid>
                          <Grid item>
                            <PoweredByStripe />
                          </Grid>
                        </Grid>
                      </GroupTitle>
                      <GroupContent>
                        <GroupItem wide>
                          <Input name="name" label="Cardholder Name" validate={required} />
                        </GroupItem>
                        <GroupItem wide>
                          <Stripe name="card" label="Card Details" validate={requireCard} />
                        </GroupItem>
                        <GroupItem wide>
                          {submitError && <FormLabel error>{submitError}</FormLabel>}
                        </GroupItem>
                        <GroupItem wide>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={pageType === ADD_NEW_CARD_PAGE ? true : saveCard}
                                onChange={() => setSaveCard(!saveCard)}
                                name="willSave"
                                disabled={pageType === ADD_NEW_CARD_PAGE}
                              />
                            }
                            label="Save card details"
                          />
                        </GroupItem>
                        <GroupItem wide>
                          <Typography>
                            You can securely store your card details on Stripe to make future
                            checkouts faster. Saving a Card is required for Auto-Refill.
                          </Typography>
                        </GroupItem>
                      </GroupContent>
                    </Group>
                  </>
                )}
                {pageType === SHIPMENT_CREDIT_TAB && (
                  <div className={classes.shipmentCredit}>
                    <Typography className={classes.creditInfo}>
                      If you have received a shipment credit code from Rollo, please enter it below.
                    </Typography>
                    <Input
                      name="creditCode"
                      label="Shipment credit code"
                      validate={composeValidators(required, creditCodeValidator)}
                      changeProxy={formatCreditCode}
                    />
                  </div>
                )}
                {pageType !== ADD_BALANCE_PAGE && pageType !== ADD_NEW_CARD_PAGE && (
                  <GroupItem wide>
                    {submitError && <FormLabel error>{submitError}</FormLabel>}
                  </GroupItem>
                )}
              </div>
            </BillDialog>
          </form>
        );
      }}
    />
  );
};

const RefillDialog = (props) => (
  <Elements stripe={stripePromise()}>
    <RefillDialogImpl {...props} />
  </Elements>
);

export default RefillDialog;
