import React, { useEffect, useMemo, useState } from 'react';
import { GET_LIST, useQueryWithStore, useDataProvider } from 'react-admin';
import { useField } from 'react-final-form';
import { CircularProgress, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Input } from 'components/form';

const useAutocompleteStyles = makeStyles((theme) => ({
  input: {
    padding: `${theme.spacing(1, 0, 1.5)} !important`,
  },

  popupIndicator: {
    color: `${theme.palette.primary.light} !important`,
  },

  paper: {
    margin: 0,
    boxShadow: theme.shadows[8],
  },
}));

const defaultValueMapper = (state) => (state ? state.code : null);

const StateSelectImpl = (props) => {
  const {
    name,
    options,
    validate,
    disabled,
    valueMapper = defaultValueMapper,
    specialMapper = (state) => state.code,
    ...rest
  } = props;

  const classes = useAutocompleteStyles();
  const [inputVal, setInputValue] = useState('');
  const [selectOptions, setSelectOptions] = useState(options);

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField(name, { validate: options.length > 0 ? validate : undefined });

  let selectedState = options.find((o) => valueMapper(o) === value);

  if (value.length && !selectedState && options?.length) {
    selectedState = options.find((o) => specialMapper(o) === value);

    onChange(valueMapper(selectedState));
  }
  const isStateExists = !!selectedState;

  useEffect(() => {
    if (!isStateExists && options?.length) {
      onChange(null);
    }
  }, [isStateExists]); //eslint-disable-line

  const handleChange = (event, option) => {
    if (!option) {
      return;
    }

    onChange(valueMapper(option));

    const el = document.activeElement;
    if (el instanceof HTMLElement) {
      el.blur();
    }
  };

  const handleInputChange = (e, value) => {
    setInputValue(value);
  };

  useEffect(() => {
    setSelectOptions(
      options.filter((opt) => {
        const searchVal = inputVal.trim().toLowerCase();
        if (!searchVal) return true;
        const indexOfCode = opt.code.toLowerCase().indexOf(searchVal);
        const indexOfName = opt.name.toLowerCase().indexOf(searchVal);
        return indexOfCode === 0 || indexOfName === 0;
      })
    );
  }, [inputVal]); //eslint-disable-line

  return (
    <Autocomplete
      disableClearable
      classes={classes}
      disabled={options.length > 0 ? disabled : true}
      options={selectOptions}
      filterOptions={createFilterOptions({
        stringify: (option) => `${option.code} ${option.name}`,
        matchFrom: 'any',
        ignoreCase: true,
      })}
      value={isStateExists ? selectedState : null}
      getOptionLabel={(option) => option.name}
      popupIcon={<ExpandMoreOutlined />}
      renderInput={(params) => <SearchField {...rest} {...params} error={touched && error} />}
      renderOption={(option) => <Option option={option} />}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
};

const useSearchFieldStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),

    '& .MuiFormLabel-root': {
      color: theme.palette.secondary.contrastText,
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: '#E0E0E0',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
}));

const SearchField = ({ inputProps, ...props }) => {
  const classes = useSearchFieldStyles();

  const isChrome = navigator.userAgent.includes('Chrome');

  return (
    <>
      <TextField
        {...props}
        inputProps={{ ...inputProps, autoComplete: `${isChrome ? 'chrome-' : ''}off` }}
        classes={classes}
        error={!!props.error}
      />
      {props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </>
  );
};

const useOptionStyles = makeStyles((theme) => ({
  stateCode: {
    fontWeight: 'bold',
    color: theme.palette.secondary.contrastText,
    width: theme.spacing(5),
  },
}));

const Option = ({ option }) => {
  const classes = useOptionStyles();

  return (
    <>
      <span className={classes.stateCode}>{option.code}</span>
      {option.name}
    </>
  );
};

const StateSelect = ({ label, name, countryCode, ...rest }) => {
  const dataProvider = useDataProvider();

  const [statesData, setStatesData] = useState<any>([]);
  const [statesLoading, setStatesLoading] = useState<boolean>(false);

  const {
    data: countriesData,
    loading: countriesLoading,
    error: countriesError,
  } = useQueryWithStore({
    type: GET_LIST,
    resource: 'countries',
    payload: { pagination: { perPage: 999 } },
  });

  const country = useMemo(
    () => countriesData?.find((c) => c.code === countryCode),
    [countriesData, countryCode]
  );

  useEffect(() => {
    if (!country?.id) return;

    setStatesLoading(true);

    dataProvider
      .getManyReference('states', { id: country?.id })
      .then(({ data }) => setStatesData(data))
      .finally(() => setStatesLoading(false));
  }, [country?.id, dataProvider]);

  if (countriesLoading || statesLoading) {
    return <CircularProgress />;
  }

  if (!country || !countryCode || countriesError) {
    return <StateSelectImpl label={label} name={name} disabled options={[]} {...rest} />;
  }

  if (!statesData.length) return <Input label={`${label} (optional)`} name={name} {...rest} />;

  if (statesData?.some((state) => state?.country?.code !== countryCode)) {
    return <StateSelectImpl label={label} name={name} disabled options={[]} {...rest} />;
  }

  return <StateSelectImpl label={label} name={name} options={statesData} {...rest} />;
};

export default StateSelect;
